<template>
  <div :class="classObject" :style="styleOverride">
    <div :class="{ 'chart-section-loading-overlay': true, 'chart-section-loading-overlay-hidden': !loading }">
      <slot name="loading-overlay">
        <font-awesome-icon :icon="['fas', 'fa-circle-notch']" class="fa-spin fa-light fa-2x" aria-hidden="true" />
      </slot>
    </div>
    <div :class="{ 'chart-section-wrapper': true, 'chart-section-wrapper-loading': loading }">
      <div class="chart-section-header">
        {{ header }}

        <button class="btn btn-sm btn-outline-secondary ml-auto mr-2"
          role="link"
          title="Refresh widget"
          @click="reloadChartClicked">
          <font-awesome-icon :icon="['fa', 'arrow-rotate-right']" fixed-width />
        </button>

        <!-- NOTE: must have obvious link from External Analytics Dashboard to External Reports area (see ABT-2166) -->
        <button class="btn btn-sm btn-outline-secondary"
          role="link"
          title="Preview report"
          @click="previewReportClicked">
          <font-awesome-icon :icon="['fas', 'file-lines']" fixed-width />
        </button>
      </div>
      <template v-if="error">
        <slot name="error">
          <div class="fade-list-item notification notification-error notification-inline button-area">
            <p>
              <font-awesome-icon :icon="['fas', 'circle-exclamation']" />
              {{ error }}
            </p>
          </div>
        </slot>
      </template>
      <div class="chart-section-body" v-if="loading">
        <slot name="loading-body" />
      </div>
      <div class="chart-section-body" v-else>
        <slot name="body">
          Default Body in Chart Section
        </slot>
      </div>
    </div>
  </div>
</template>

<script lang="js">

export default {
  props: {
    sizeClasses: {
      type: Object,
      required: false,
      default: null,
    },
    styleOverride: {
      type: String,
      required: false,
      default: 'height: 24rem;',
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: String,
      required: false,
      default: '',
    },
    header: {
      type: String,
      required: false,
      default: "Default Header for Chart"
    },
  },
  computed: {
    classObject() {
      const result = {
        'chart-section': true,
      }
      if (this.sizeClasses) {
        result[this.sizeClasses] = true;
      } else {
        Object.assign(result, { 'col-12': true });
      }
      return result;
    },
  },

  methods: {
    reloadChartClicked() {
      this.$emit("reloadChart");
    },

    previewReportClicked() {
      this.$emit("previewReport");
    },
  }
}
</script>

<style scoped>
  .chart-section {
    padding: 0.5rem;
  }
  .chart-section-wrapper {
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 3px;
    padding: 0.5rem 1rem;
    height: 100%;
    transition: background-color 0.2s;
  }
  .chart-section-wrapper-loading {
    background-color: #0006;
  }
  .chart-section-loading-overlay {
    position: absolute;
    display: flex;
    width: 97.5%;
    height: 90%;
    align-items: center;
    justify-content: center;
    transition: opacity 0.2s;
    z-index: 2;
  }
  .chart-section-loading-overlay-hidden {
    opacity: 0;
    z-index: -1;
  }
  .chart-section-header {
    margin-bottom: 0.5rem;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }
  .chart-section-body {
    height: 90%;
  }
</style>
