<template>
  <chart-section
    sizeClasses="col-lg-3 col-md-6 col-sm-12"
    styleOverride="min-height: 6rem;"
    :loading="loading"
    :error="error"
    :header="header"
    @reloadChart="reloadChart"
    @previewReport="previewReport"

  >
    <template v-slot:body>
      <span :id="chartId" :title="chartOptions.tooltip">
        {{ formattedResult }}
      </span>
    </template>
  </chart-section>
</template>

<script lang="js">
import ChartSection from "@/src/components/shared/ChartSection.vue";

const DEFAULTS = {
  FIELD_NAME: 'total_offers',
};

export default {
  components: {
    ChartSection,
  },
  props: {
    chartId: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      required: false,
      default: () => {},
    },
    result: {
      type: Number,
      required: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: String,
      required: false,
      default: '',
    },
    header: {
      type: String,
      required: false,
      default: "Default Header for Single Number Chart"
    }
  },
  computed: {
    formattedResult() {
      const configObject = this.config || {};
      const resultFieldName = configObject.fieldName || DEFAULTS.FIELD_NAME
      const rawResult = this.result[resultFieldName];

      if (rawResult == null) return '';

      if (typeof rawResult == 'number') return this.formatNumber(rawResult);

      return JSON.stringify(rawResult);
    },
    chartOptions() {
      const result = {};
      const configObject = this.config || {};
      if (configObject.tooltip) result.tooltip = configObject.tooltip;
      return result;
    }
  },
  methods: {
    reloadChart() {
      this.$emit("reloadChart");
    },

    previewReport() {
      this.$emit("previewReport");
    },

    formatNumber(rawResult) {
      return rawResult.toLocaleString();
    },
  }
}
</script>
