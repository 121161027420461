<template>
  <chart-section
    :sizeClasses="sizeClasses"
    :loading="loading"
    :error="error"
    :header="header"
    @reloadChart="reloadChart"
    @previewReport="previewReport"

  >
    <template v-slot:body>
      <Bar
        v-if="!loading && !error"
        :id="chartId"
        :options="chartOptions"
        :data="chartData"
      />
      <div v-else />
    </template>
  </chart-section>
</template>

<script lang="js">
import ChartSection from "@/src/components/shared/ChartSection.vue";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  BarElement,
  CategoryScale,
  LinearScale,
  Legend,
} from 'chart.js'

// These two functions defines the labels hit boxes

const findLabel = (labels, evt) => {
  let found = false;
  let res = null;

  labels.forEach(l => {
    l.labels.forEach((label, index) => {
      if (evt.x > label.x && evt.x < label.x2 && evt.y > label.y && evt.y < label.y2) {
        res = {
          label: label.label,
          index
        };
        found = true;
      }
    });
  });

  return [found, res];
};

const getLabelHitboxes = (scales) => (Object.values(scales).map((s) => ({
  scaleId: s.id,
  labels: s._labelItems.map((labelItem, i) => ({
    x: labelItem.options.translation[0] - s._labelSizes.widths[i] / 2,
    x2: labelItem.options.translation[0] + s._labelSizes.widths[i] / 2,
    y: labelItem.options.translation[1] - s._labelSizes.heights[i] / 2,
    y2: labelItem.options.translation[1] + s._labelSizes.heights[i] / 2,
    label: labelItem.label,
    index: i
  }))
})));

import { Bar } from 'vue-chartjs'

const DEFAULTS = {
  INDEX_AXIS: 'y',
  DATASET_LABEL_NAME: 'Default Label for Dataset in Stacked Horizontal Bar Chart',
  SCALE_X_TITLE_TEXT: 'Default Title for X Scale in Stacked Horizontal Bar Chart',
  RESULT_LABEL_FIELD: 'organ_name',
  BAR_COLOURS: ['#98D9D9'],
  STACKED: false,
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
)

export default {
  components: {
    ChartSection,
    Bar
  },
  props: {
    chartId: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      required: false,
      default: () => {},
    },
    results: {
      required: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: String,
      required: false,
      default: '',
    },
    sizeClasses: {
      type: String,
      required: false,
      default: 'col-md-6 col-sm-12',
    },
    header: {
      type: String,
      required: false,
      default: "Default Header for Stacked Horizontal Bar Chart"
    }
  },
  computed: {
    chartOptions() {
      const result = {
        indexAxis: DEFAULTS.INDEX_AXIS,
        scales: {
          x: {
            title: {
              display: true,
              text: DEFAULTS.SCALE_X_TITLE_TEXT,
            },
            stacked: true,
          },
          y: {
            grid: {
              display: false,
            },
            stacked: true,
          },
        },
        plugins: {
          legend: {
            display: true
          },
          title:{
            text:"Chart Title",
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        onHover: (event, chartElement) => {
          // changes cursor to a point on hover
          event.native.target.style.cursor = chartElement.length == 1 ? 'pointer' : 'default';
        },
        onClick: (event, elements, chart) => {
          if (elements[0]) {
            const x = elements[0].datasetIndex
            const i = elements[0].index;
            this.$emit("previewReport", {id: chart.canvas.id, label: chart.data.datasets[x].label, section: this.getCode(i)})
          }
        },
      };
      const configObject = this.config || {};
      if (configObject.xTitle) result.scales.x.title.text = configObject.xTitle;
      return result;
    },
    chartData() {
      const configObject = this.config || {};
      const resultsArray = this.results || [];
      const datasetConfigs = configObject.datasets || [];
      const resultLabelField = configObject.resultLabelField || DEFAULTS.RESULT_LABEL_FIELD;
      const barColours = configObject.barColours || DEFAULTS.BAR_COLOURS;
      const barHoverColours = configObject.barHoverColours || DEFAULTS.BAR_COLOURS;
      const datasetLabelName = DEFAULTS.DATASET_LABEL_NAME;
      return {
        labels: this.buildLabels(datasetConfigs),
        datasets: this.buildDatasets(datasetConfigs, resultsArray, resultLabelField, barColours, barHoverColours),
      };
    },
  },
  methods: {
    reloadChart() {
      this.$emit("reloadChart");
    },

    previewReport() {
      this.$emit("previewReport");
    },

    buildLabels(datasetConfigs) {
      return datasetConfigs.map((datasetConfig) => {
        return datasetConfig.label;
      });
    },
    buildDatasets(datasetConfigs, resultsArray, resultLabelField, barColours, barHoverColours) {
      return resultsArray.map((result, index) => {
        const dataset = {
          label: result[resultLabelField],
          backgroundColor: this.buildBarColour(barColours, index),
          hoverBackgroundColor: this.buildBarColour(barHoverColours, index),
          data: [],
        };
        dataset.data = datasetConfigs.map((datasetConfig) => {
          return result[datasetConfig.dataField]
        });
        return dataset;
      });
    },
    buildBarColour(barColours, index) {
      return barColours[index % barColours.length];
    },

    getCode(index) {
      const configObject = this.config || {};
      const datasetConfigs = configObject.datasets || [];

      return datasetConfigs[index].code
    },
  },
}
</script>
